.align_left {
  text-align: left;
}

.align_center {
  text-align: center;
}

.align_right {
  text-align: right;
}

.font_28 {
  font-size: 28px;
}

.font_24 {
  font-size: 24px;
}

.font_20 {
  font-size: 20px;
}

.font_17 {
  font-size: 17px;
}

.font_15 {
  font-size: 15px;
}

.font_13 {
  font-size: 13px;
}
.color_black {
  color: #000000;
}

.color_light_black {
  color: #666d75;
}

.color_blue {
  color: rgba(0, 149, 255, 1);
}

.color_blue_op {
  color: rgba(0, 149, 255, 0.5);
}

.color_light_blue {
  color: rgba(128, 223, 255, 1);
}

.color_red {
  color: rgba(255, 51, 51, 1);
}

.color_grey {
  color: rgba(132, 140, 148, 1);
}

.color_white {
  color: #ffffff !important;
}

.color_transparent {
  color: transparent;
}

.color_pink {
  color: rgba(193, 76, 138, 1);
}

.color_yellow {
  color: rgba(203, 145, 47, 1);
}

.color_green {
  color: rgba(68, 131, 97, 1);
}

.color_purple {
  color: rgba(144, 101, 176, 1);
}

.bg_transparent {
  background-color: transparent;
}

.bg_black {
  background-color: rgba(0, 0, 0, 0.3);
}

.bg_blue {
  background-color: rgba(0, 149, 255, 0.5);
}

.bg_blue_100 {
  background-color: rgba(0, 149, 255, 1);
}

.bg_light_blue {
  background-color: rgba(233, 242, 254, 1);
}

.bg_red {
  background-color: rgba(255, 51, 51, 0.5);
}

.bg_red_100 {
  background-color: rgba(255, 51, 51, 1);
}

.bg_grey {
  background-color: rgba(132, 140, 148, 0.1);
}

.bg_grey_100 {
  background-color: rgba(132, 140, 148, 1);
}

.bg_white {
  background-color: rgba(255, 255, 255, 1);
}

.bg_pink {
  background-color: rgba(249, 238, 243, 0.8);
}

.bg_yellow {
  background-color: rgba(251, 243, 219, 1);
}

.bg_green {
  background-color: rgba(237, 243, 236, 1);
}

.bg_purple {
  background-color: rgba(244, 240, 247, 0.8);
}

.weight_700 {
  font-weight: 700;
}

.weight_500 {
  font-weight: 500;
}

.weight_400 {
  font-weight: 400;
}

.font_italic {
  font-style: italic;
}

.font_underline {
  text-decoration: underline;
}

.f4 {
  font-size: calc(1rem * (4 / 14));
}

.f8 {
  font-size: calc(1rem * (8 / 14));
}

.f9 {
  font-size: calc(1rem * (9 / 14));
}

.f10 {
  font-size: calc(1rem * (10 / 14));
}

.f11 {
  font-size: calc(1rem * (11 / 14));
}

.f12 {
  font-size: calc(1rem * (12 / 14));
}

.f13 {
  font-size: calc(1rem * (13 / 14));
}

.f14 {
  font-size: 1rem;
}

.f15 {
  font-size: calc(1rem * (15 / 14));
}

.f16 {
  font-size: calc(1rem * (16 / 14));
}

.f17 {
  font-size: calc(1rem * (17 / 14));
}

.f18 {
  font-size: calc(1rem * (18 / 14));
}

.f19 {
  font-size: calc(1rem * (19 / 14));
}

.f20 {
  font-size: calc(1rem * (20 / 14));
}

.f21 {
  font-size: calc(1rem * (21 / 14));
}

.f22 {
  font-size: calc(1rem * (22 / 14));
}

.f23 {
  font-size: calc(1rem * (23 / 14));
}

.f24 {
  font-size: calc(1rem * (24 / 14));
}

.f25 {
  font-size: calc(1rem * (25 / 14));
}

.f26 {
  font-size: calc(1rem * (26 / 14));
}

.f27 {
  font-size: calc(1rem * (27 / 14));
}

.f28 {
  font-size: 2rem;
}
